import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import ContentArea from '../../molecules/common/ContentArea';
import ContentAreaMainHeading from '../../molecules/common/ContentAreaMainHeading';

import LinkButtonPrimary from '../../atoms/LinkButtonPrimary';

import * as styles from './index.module.css';

const NotFondTemplate = ({ path }) => (
  <div className="root">
    <SEO path={path} title="指定されたページは存在しません" />
    <SiteHeader />

    <PageDefault>
      <ContentWrapper>
        <main className={styles['main']}>
          <section>
            <ContainerDefault>
              <ContentArea>
                <div className={styles['headingWrapper']}>
                  <ContentAreaMainHeading heading="指定されたページは存在しません" />
                </div>

                <div className={styles['paragraphWrapper']}>
                  <p className={styles['paragraph']}>
                    URLが誤っているか、コンテンツが削除された可能性があります。
                  </p>
                </div>

                <InlineBlockWrapper
                  className={styles['backToHomeButtonWrapper']}
                >
                  <LinkButtonPrimary label="ホームに戻る" to="/" />
                </InlineBlockWrapper>
              </ContentArea>
            </ContainerDefault>
          </section>
        </main>
      </ContentWrapper>
    </PageDefault>

    <GlobalNavigation />

    <SiteFooter />
  </div>
);

export default NotFondTemplate;
